<template>
  <v-container fluid>
    <div>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-comment"
            >
              {{ $lang.labels.comment }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="messaging.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                          class="required-asterisk copy-name-icon"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          append-icon="mdi-vector-combine"
                          data-cy="messaging-name"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        data-cy="messaging-help"
                        @click="changeStatus()"
                      >
                        {{ messaging.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters>
                    <v-col
                      cols="9"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="messaging.service"
                        :items="formatServices"
                        :label="$lang.labels.service"
                        outlined
                        dense
                        required
                        :rules="[v => !!v || $lang.labels.required]"
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                        data-cy="messaging-service"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item.value">{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="9"
                      class="pr-sm-1 mb-3"
                    >
                      <div class="d-inline-flex align-center" style="width: 100%">
                        <v-autocomplete
                          v-model="messaging.credentialId"
                          outlined
                          dense
                          :items="credentials"
                          :loading="isLoadingCredentials"
                          :search-input.sync="searchCredentials"
                          clearable
                          hide-no-data
                          hide-details
                          item-text="name"
                          item-value="id"
                          required
                          :rules="[v => !!v || $lang.labels.required]"
                          class="required-asterisk"
                          :label="$lang.labels.credential"
                          :placeholder="messaging.service ? $lang.actions.startTyping : $lang.errors.selectServiceFirst"
                          prepend-inner-icon="mdi-cloud-search-outline"
                          data-cy="messaging-credentialId"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                        <v-btn
                          icon
                          light
                          color="primary"
                          class="ml-1"
                          data-cy="refresh-credentials"
                          @click="searchCredentialsFunction(searchCredentials)"
                        >
                          <v-icon
                            dense
                            small
                          >
                            mdi-refresh
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      class="align-center"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': messaging.credentialId, 'color-secondary': !messaging.credentialId}"
                        data-cy="open-credential"
                        @click="messaging.credentialId ? openCredentialMethod() : ''"
                      >
                        {{ $lang.actions.openCredentialLink }}
                      </p>
                    </v-col>
                    <v-col
                      cols="9"
                      class="pr-sm-1"
                      style="position: relative"
                    >
                      <v-autocomplete
                        v-model="messaging.processId"
                        outlined
                        dense
                        clearable
                        :items="processes"
                        :loading="isLoadingProcesses"
                        :search-input.sync="searchProcesses"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.process"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        required
                        class="required-asterisk"
                        :readonly="!userCanEdit"
                        data-cy="messaging-processId"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item.id">{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="2"
                      class="align-center"
                    >
                      <p
                        :class="{'pt-1 pl-1 clickable-simple': true, 'color-primary': messaging.processId, 'color-secondary': !messaging.processId}"
                        data-cy="open-process"
                        @click="messaging.processId ? openProcess() : ''"
                      >
                        {{ $lang.actions.openProcess }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="messaging.priority"
                        :items="priorities"
                        :label="$lang.labels.priority"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="messaging-priority"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <v-combobox
                        v-model="messaging.instanceIds"
                        outlined
                        dense
                        multiple
                        chips
                        :items="instances"
                        :label="$lang.labels.instances"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        clearable
                        :readonly="!userCanEdit"
                        data-cy="messaging-instanceIds"
                        :rules="[v => v.length < 49 || 'Max 48 instances']"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="item">{{ item }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-inline-flex mb-2"
                    >
                      <v-checkbox v-model="messaging.isGdprRelevant" hide-details data-cy="messaging-gdpr" :label="$lang.labels.gdpr"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class="mt-2">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          data-cy="messaging-back"
                          :to="{ name: 'messaging', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="messaging.name"
                          :title="$lang.actions.delete"
                          type="messaging"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'messaging-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteMessagingFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'messaging' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right d-flex justify-end"
                    >
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit || !isEdit"
                        color="accent"
                        class="button-default-width color-accent-text"
                        @click="copyCreateResource(messaging, 'messagingCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                      >
                        {{ $lang.actions.copyCreate }}
                      </v-btn>
                      <action-button-with-confirmation
                        v-if="showCreateProcessForTriggerButton"
                        :action-text="$lang.actions.triggerCreateProcess"
                        :title="$lang.actions.createProcess"
                        :is-disabled="!isUserProcessCreator"
                        :button-text="$lang.actions.submit"
                        :button-color="'success'"
                        :data-cy="'messaging-create-process'"
                        :simple-error="err"
                        :btn-icon="'mdi-floppy'"
                        class="ml-2"
                        @submit="createProcessForTrigger()"
                        @closeDialog="() => err = $lang.errors.processRequired"
                      />
                      <v-btn
                        v-else
                        :disabled="!valid || lock"
                        color="success"
                        class="button-default-width ml-2"
                        data-cy="messaging-submit"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreMessaging($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col
                    cols="12"
                  >
                    <div class="d-flex justify-space-between">
                      <p class="pa-0 ma-0">{{ $lang.labels.comment }}</p>
                      <p
                        class="pa-0 ma-0 clickable-simple color-primary"
                        data-cy="edit-comment"
                        @click="lock || !userCanEdit ? '' : showMarkdown = true"
                      >
                        {{ $lang.actions.edit }}
                      </p>
                    </div>
                    <div class="mark-class pa-1 mb-2 mt-1 clickable-simple" data-cy="messaging-comment" @click="lock || !userCanEdit ? '' : showMarkdown = true" v-html="compiledMarkdown"></div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="showMarkdown" v-model="showMarkdown" max-width="80%" width="80%">
        <markdown-modal
          :markdown-data="messaging.comment"
          @save="messaging.comment = $event"
          @closeDialog="showMarkdown = false"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import copy from 'copy-to-clipboard'
import MarkdownModal from '@/components/ui/modals/MarkdownModal'
import {
  getTriggerMessagingByIdUsingGET as getMessaging,
  disableTriggerMessagingUsingGET as disableMessaging,
  enableTriggerMessagingUsingGET as enableMessaging,
  updateTriggerMessagingUsingPUT as updateMessaging,
  createTriggerMessagingUsingPOST as createMessaging,
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getHistoriesUsingGET as getHistories,
  getSettingsUsingGET as getSettings,
  getValidationRulesUsingGET as getValidationRules, getProcessCredentialsUsingGET as getCredentials,
  getProcessCredentialByIdUsingGET as getCredential,
  deleteMessagingTriggerUsingDELETE as deleteMessaging, getInstancesUsingGET as getInstances
} from '@/utils/api'
import {
  doesProcessNameExist,
  createProcessForTriggerData,
  copyCreateResource,
  getRolesWithoutAuth
} from '@/utils/helpers'
import { marked } from 'marked'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { definitions } from '@/utils/definitions'
import { mapActions } from 'vuex'

export default {
  components: {
    ActionButtonWithConfirmation,
    Table,
    MarkdownModal,
    UserRolesSelect
  },
  data() {
    return {
      showMarkdown: false,
      validationKey: 10000,
      messagingHelpShow: false,
      tab: 0,
      errUrlValidation: '',
      errPathVarValidation: '',
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      messaging: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        comment: '',
        processId: '',
        credentialId: '',
        status: '',
        isGdprRelevant: false,
        service: '',
        priority: 64,
        instanceIds: []
      },
      instances: [],
      services: definitions.TriggerMessaging.properties.service.enum,
      processes: [],
      searchProcesses: '',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      searchCredentials: '',
      credentials: [],
      isLoadingCredentials: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isUserProcessCreator: false
    }
  },
  computed: {
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    },
    compiledMarkdown: function() {
      return marked.parse(this.messaging?.comment) || ''
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatServices() {
      return this.services.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.messaging.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.messaging.modifiedOn)}, ID: ${this.$route.params.id}`
    },
    messagingRoles() {
      return [
        ...this.editRolesIds.map((x) => ({ permissionType: 'EDIT', roleId: x })),
        ...this.useRolesIds.map((x) => ({ permissionType: 'USE', roleId: x })),
        ...this.viewRolesIds.map((x) => ({ permissionType: 'VIEW', roleId: x }))
      ]
    },
    showCreateProcessForTriggerButton() {
      return this.isUserProcessCreator && !this.messaging.processId
    }
  },
  watch: {
    tab(val) {
      if (val === 2) this.fetchHistory()
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'messagingEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'TRIGGER_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        this.isUserProcessCreator = !!user.roles.find((x) => x.name === 'PROCESS_CREATOR')

        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          this.isLoadingProcesses = true
          this.isLoadingCredentials = true

          getMessaging({ id: this.$route.params.id })
            .then((res) => {
              this.messaging = res.data.data

              this.editRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (this.messaging.comment === null) {
                this.messaging.comment = ''
              }

              if (this.messaging.priority === null || this.messaging.priority === undefined) {
                this.messaging.priority = 64
              }

              getProcess({ id: res.data.data.processId })
                .then((res) => {
                  this.processes = [res.data.data]

                  this.isLoadingProcesses = false
                })
                .catch((err) => {
                  this.addSnackbar({
                    message:  err,
                    timeout: 5000,
                    color: 'error'
                  })                    })

              getCredential({ id: res.data.data.credentialId })
                .then((res) => {
                  this.credentials = [res.data.data]

                  this.isLoadingCredentials = false
                })
                .catch((err) => {
                  this.addSnackbar({
                    message:  err,
                    timeout: 5000,
                    color: 'error'
                  })                    })

              this.loading = false

            })
            .catch((err) => {
              this.addSnackbar({
                message:  err,
                timeout: 5000,
                color: 'error'
              })                })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy || this.$route.params.restore) {
            this.messaging = this.$route.params.copy ? JSON.parse(JSON.stringify(this.$route.params.copy)) : JSON.parse(JSON.stringify(this.$route.params.restore))

            if (this.messaging.comment === null) {
              this.messaging.comment = ''
            }

            if (this.messaging.priority === null || this.messaging.priority === undefined) {
              this.messaging.priority = 64
            }

            getProcess({ id: this.messaging.processId })
              .then((res) => {
                this.processes = [res.data.data]

                this.isLoadingProcesses = false
              })
              .catch((err) => {
                this.addSnackbar({
                  message:  err,
                  timeout: 5000,
                  color: 'error'
                })                  })

            getCredential({ id: this.messaging.credentialId })
              .then((res) => {
                this.credentials = [res.data.data]

                this.isLoadingCredentials = false
              })
              .catch((err) => {
                this.addSnackbar({
                  message:  err,
                  timeout: 5000,
                  color: 'error'
                })                  })

            this.loading = false
          } else {
            this.messaging.service = this.services[0]
            this.loading = false
          }
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    copyCreateResource,
    copyName() {
      if (this.messaging.name) {
        copy(this.messaging.name)

        this.addSnackbar({
          message:  this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message:   this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    deleteMessagingFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteMessaging({ id: this.messaging.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    searchCredentialsFunction(val = '') {
      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = this.messaging.service

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data.items
          if (res.data.data.items.length === 1) {
            this.messaging.credentialId = res.data.data.items[0].id
          }
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    openProcess() {
      if (this.messaging.processId) window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.messaging.processId}`, '_blank')
    },
    openCredentialMethod() {
      if (this.messaging.credentialId) window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${this.messaging.credentialId}`, '_blank')
    },
    fetchHistory(options) {
      if (!this.messaging.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.messaging.id
      obj.resourceType = 'TRIGGER_MESSAGING'

      getHistories(obj)
        .then((res) => {
          if (res.status !== 200) {

            this.addSnackbar({
              message:  this.$lang.errors.historyFetch,
              timeout: 5000,
              color: 'error'
            })

            return
          }

          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    restoreMessaging(data) {
      this.messaging = data

      if (!this.messaging.comment) this.messaging.comment = ''

      if (this.messaging && this.messaging.roles) {
        this.editRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.messaging.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }

      this.formatSizeUnits()

      getProcess({ id: this.messaging.processId })
        .then((res) => {
          this.processes = [res.data.data]

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    async init() {
      const instances = await getInstances()

      this.instances = instances.data.data.items.filter((y) => y.type === 'PROCESSING').map((x) => x.name)

      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
        getValidationRules()
          .then((res) => {
            this.validationRules = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || '',
        isSystem: false
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    //TODO maybe somehow DRY with same functions in other triggers
    async createProcessForTrigger() {
      const processNameExists = await doesProcessNameExist(this.messaging.name)

      this.lock = true
      this.loading = true

      const res = await createProcessForTriggerData(this.messaging.name, processNameExists, this.messagingRoles)

      if (res && res.status !== 200) {
        // this.err =
        //     res?.response?.data?.statusText ||
        //     (this.$lang.errors.processCreate)
        this.addSnackbar({
          message:  res?.response?.data?.statusText ||
          (this.$lang.errors.processCreate),
          timeout: 5000,
          color: 'error'
        })
        // setTimeout(() => (this.err = ''), 5000)
        this.lock = false
        this.loading = false
      } else if (res) {
        const processId = res.data.data.id
        const processName = res.data.data.name

        const existingProcess = this.processes.find((process) => process.id === processId)

        if (!existingProcess) {
          this.processes.push({ id: processId, name: processName })
        }

        this.messaging.processId = processId
        this.addSnackbar({
          message:  this.$lang.success.processCreated,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false
        this.loading = false
        this.showCreateProcessDialog = false
        this.submit()
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {

        this.addSnackbar({
          message:  this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      const copyOfMessaging = JSON.parse(JSON.stringify(this.messaging))

      let res = null

      const { id } = copyOfMessaging

      if (!this.isEdit) {
        delete copyOfMessaging.id
        copyOfMessaging.status = 'ACTIVE'
      }
      delete copyOfMessaging.createdOn
      delete copyOfMessaging.modifiedOn

      copyOfMessaging.roles = this.messagingRoles

      try {

        res = this.isEdit ? await updateMessaging({ id, body: copyOfMessaging }) : await createMessaging({ body: copyOfMessaging })

        if (res && res.status !== 200) {

          const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.messagingUpdate : this.$lang.errors.messagingCreate)

          this.addSnackbar({
            message:  errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.messagingUpdated : this.$lang.success.messagingCreated

        this.addSnackbar({
          message:  successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

        if (!this.isEdit) {
          this.messaging = res.data.data
          this.isEdit = true
          this.$router.push({
            name: 'messagingEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message:  err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.messaging.status === 'ACTIVE' ? await disableMessaging({ id: this.messaging.id }) : await enableMessaging({ id: this.messaging.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message:  this.$lang.errors.messagingStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
        }

        const successMessage = this.messaging.status !== 'ACTIVE' ? this.$lang.success.messagingActivated : this.$lang.success.messagingDeactivated

        this.addSnackbar({
          message:  successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.messaging.status = res.data.data.status

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message:  err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
